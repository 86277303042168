import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { useRecoilState } from 'recoil';
import isArray from 'lodash/isArray';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { methodSearchParamState } from '../searchFiltersTabs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const GroupHeader = styled(Button)(() => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    backgroundColor: 'white',
}));

const GroupItems = styled('ul')({
    padding: 0,
});

const MethodGroup = ({searchParam, groupParams, groupIndexes}) => {
    const [methodSearchParams, setMethodSearchParams] = useRecoilState(methodSearchParamState);
    const searchParamOptions = isArray(methodSearchParams[searchParam]) ? methodSearchParams[searchParam] : [];
    const groupOptions = searchParamOptions.filter(o => o.type === groupParams.group);
    const [groupOpen, setGroupOpen] = useState(groupOptions.length > 0);

    const handleGroupChecked = (allOptionsData) => {
        const otherSearchParamOptions = searchParamOptions.filter(o => o.type !== groupParams.group);
        setMethodSearchParams(
            {
              ...methodSearchParams,
              [searchParam]: otherSearchParamOptions.concat(allOptionsData),
            }
        )
      };
    const allOptionsSelected = groupOptions.length == groupParams.children.length;
    return (
      <li key={groupParams.group}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 2 }}
          // checked if all children are selected
          checked={allOptionsSelected}
          value={groupParams.group}
          onChange={(e) => {
            const isChecked = e.target.checked;
            let allOptionsData = [];
            if (isChecked) {
              // Select all options
              allOptionsData = groupParams.children.map(
                (child) => ({ type: child.props.option.type, subtype: child.props.option.subtype})
              );
            }
            handleGroupChecked(allOptionsData);
          }}
        />
        <GroupHeader onClick={
              () => {
                setGroupOpen(!groupOpen);
              }
          }>
          {`${groupIndexes[groupParams.group]}. ${groupParams.group}`}
        </GroupHeader>
        { groupOpen && <GroupItems>{groupParams.children}</GroupItems> }
      </li>
    );
  }
  export default MethodGroup;
