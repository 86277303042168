import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import has from 'lodash/has';
import { methodSearchParamState } from '../searchFiltersTabs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconLabel from '../iconLabel';
import RestorationMethodFilters from './RestorationMethodFilters';
import ScientificMethodFilters from './ScientificMethodFilters';

import { FLAG_DETAILS as METHOD_FLAG_DETAILS } from '../../constants/methodPracticeFlags';
import {
  methodTypeSearchParams,
} from '../../constants/methodologies';

const MethodSearchFilters = ({filterData}) => {
  const [methodSearchParams, setMethodSearchParams] = useRecoilState(methodSearchParamState);
  
  const methodSearchParamsHandleOnChangeCheckbox = (event) => {
    const currentValue = event.target.value;
    const isChecked = event.target.checked;
    const newSearchParams = { [currentValue]: isChecked };
    if (!isChecked && has(methodTypeSearchParams, currentValue)) {
      // We unchecked a method type (i.e. restoration or scientific)
      // Lets remove the related searchParams from the search
      methodTypeSearchParams[currentValue].forEach((searchParam) => {
        newSearchParams[searchParam] = [];
      });
    }
    setMethodSearchParams(
        {
          ...methodSearchParams,
          ...newSearchParams,
        }
    );
  };
  
  return (
    <Grid container rowSpacing={2} columnSpacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Methodologies</Typography>
      </Grid>
      <Grid item xs={12}>        
        <RestorationMethodFilters filterData={filterData} />
      </Grid>
      <Grid item xs={12}>        
        <ScientificMethodFilters filterData={filterData} />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          {Object.keys(METHOD_FLAG_DETAILS).map((column) => (
            <FormControlLabel
              label={
                <IconLabel 
                  key={column} 
                  label={METHOD_FLAG_DETAILS[column].label} 
                  IconType={METHOD_FLAG_DETAILS[column].IconType}
                  iconProps={METHOD_FLAG_DETAILS[column].iconStyle ? { style: METHOD_FLAG_DETAILS[column].iconStyle } : {}}
                />}
              key={column}
              control={
                <Checkbox
                  value={METHOD_FLAG_DETAILS[column].searchParam}
                  onChange={methodSearchParamsHandleOnChangeCheckbox}
                />
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};
MethodSearchFilters.propTypes = {
  filterData: PropTypes.object.isRequired,
};

export default MethodSearchFilters;
