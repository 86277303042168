import React from 'react';
import PropTypes from 'prop-types';
import { useRecoilState } from 'recoil';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { filterUnique } from '../../utils';
import { SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM } from '../../constants/methodologies';
import { sortScientificMethods, isOptionEqualToValue } from './utils';
import MethodGroup from './MethodGroup';
import MethodOption from './MethodOption';
import { methodSearchParamState } from '../searchFiltersTabs';
import has from 'lodash/has';

const ScientificMethodFilters = ({ enabled, filterData }) => {
    const [methodSearchParams, setMethodSearchParams] = useRecoilState(methodSearchParamState);
    const options = filterUnique(filterData.allScientificMethods, ['type', 'subtype']).map((method) => {
      return ({ type: method.type, subtype: method.subtype })
    });
    options.sort(sortScientificMethods);
    // Look at options and create index for each type
    let indexCounter = 0;
    const typeIndexes = options.reduce(
      (indexes, opt) => has(indexes, opt.type) ? indexes : { ...indexes, [opt.type]:++indexCounter },{});
    const selectedValues = has(methodSearchParams, SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM) ? methodSearchParams[SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM] : [];
    return (
        <Autocomplete
          isOptionEqualToValue={isOptionEqualToValue}
          disabled={!enabled}
          limitTags={3}
          multiple
          id="scientificMethod-select-id"
          options={options}
          groupBy={(option) => option.type}
          getOptionLabel={(option) => option.subtype}
          renderOption={(props, option) => (
            <MethodOption 
              key={option.subtype}
              searchParam={SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM} 
              option={option}
            />)}
          renderInput={(params) => (
            <TextField {...params} placeholder="Science-Based Methodologies" />
          )}
          renderGroup={(params) => 
            <MethodGroup 
              key={params.group}
              searchParam={SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM} 
              groupParams={params} 
              groupIndexes={typeIndexes} 
            />}
            value={selectedValues}
            onChange={(e, value) => {
              setMethodSearchParams({
                ...methodSearchParams,
                [SCIENTIFIC_METHOD_CATEGORIES_SEARCH_PARAM]: value,
              });
                        }}
        />
    );
  };
  ScientificMethodFilters.propTypes = {
    enabled: PropTypes.bool
  };
  ScientificMethodFilters.defaultProps = {
    enabled: true
  };
export default ScientificMethodFilters;
