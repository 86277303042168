import React from 'react';
import { useRecoilState } from 'recoil';
import has from 'lodash/has';
import { orgSearchParamState } from '../searchFiltersTabs';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import { FLAG_DETAILS as ORG_FLAG_DETAILS } from '../../constants/orgFlags';
import { ORGANIZATION_NAME_SEARCH_PARAM } from '../../constants/organization';
import IconLabel from '../iconLabel';

const OrgSearchFilters = ({organizationNames}) => {
  const [orgSearchParams, setOrgSearchParams] = useRecoilState(orgSearchParamState);
  const orgSearchParamsHandleOnChangeCheckbox = (event) => {
    const currentValue = event.target.value;
    const isChecked = event.target.checked;
    setOrgSearchParams(orgSearchParams => ({...orgSearchParams, [currentValue]: isChecked}))
  };
  
  const OrganizationNameFormControl = () => {
    const options = getOptionsForOrganizationName(organizationNames);
    return (
      <FormControl fullWidth>
        <Autocomplete
          value={orgSearchParams[ORGANIZATION_NAME_SEARCH_PARAM]}
          disablePortal
          id="organization-name-select"
          options={options}
          renderInput={(params) => (
            <TextField {...params} label="Organization Name" />
          )}
          onChange={(e, value) => {
            setOrgSearchParams(
              osp => (
                {
                  ...osp, 
                  [ORGANIZATION_NAME_SEARCH_PARAM]: value.label,
                }
              )
            );
          }}
        />
    </FormControl>
    );
  };
  
  return (
    <Grid container rowSpacing={0} columnSpacing={1} sx={{ paddingLeft: 1, paddingRight: 1 }}>
      <Grid item xs={12}>
        <Typography align="center" variant="h6">Organization Services</Typography>
      </Grid>
      <Grid item xs={12}>
        <OrganizationNameFormControl />
      </Grid>
      <Grid item xs={12}>
        <FormGroup row>
          {Object.keys(ORG_FLAG_DETAILS).map((column) => (
            <FormControlLabel
              label={
              <IconLabel 
                key={column} 
                label={ORG_FLAG_DETAILS[column].label} 
                IconType={ORG_FLAG_DETAILS[column].IconType}
                iconProps={ORG_FLAG_DETAILS[column].iconStyle ? { style: ORG_FLAG_DETAILS[column].iconStyle } : {}}
              />}
              key={column}
              control={
                <Checkbox
                  value={ORG_FLAG_DETAILS[column].searchParam}
                  onChange={orgSearchParamsHandleOnChangeCheckbox}
                  checked={has(orgSearchParams, ORG_FLAG_DETAILS[column].searchParam) ? !!orgSearchParams[ORG_FLAG_DETAILS[column].searchParam] : false}
                />
              }
            />
          ))}
        </FormGroup>
      </Grid>
    </Grid>
  );
};

const getOptionsForOrganizationName = (allOrganizations) => {
  let options = allOrganizations.map((orgName) => ({label: orgName}));
  options.sort((a, b) => {
    var textA = a.label.toUpperCase();
    var textB = b.label.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  })
  return options;
}
export default OrgSearchFilters;
