import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { useRecoilState } from 'recoil';
import isArray from 'lodash/isArray';
import filter from 'lodash/filter';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { methodSearchParamState } from '../searchFiltersTabs';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MethodOption = ({searchParam, option, ...props}) => {
    const [methodSearchParams, setMethodSearchParams] = useRecoilState(methodSearchParamState);
    const isChecked = isArray(methodSearchParams[searchParam]) ? methodSearchParams[searchParam].findIndex(o => o.type === option.type && o.subtype === option.subtype) >= 0 : false;
    return (
    <li key={option.subtype} data-type={option.type} data-subtype={option.subtype} {...props}>
        <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={isChecked}
        value={option.subtype}
        onChange={(e) => {
            const searchParamOptions = isArray(methodSearchParams[searchParam]) ? methodSearchParams[searchParam] : [];
            const newSearchParamOptions = e.target.checked ? 
            searchParamOptions.concat([{type: option.type, subtype: option.subtype}]) : 
            filter(searchParamOptions, (o) => !(o.type === option.type && o.subtype === option.subtype));
            setMethodSearchParams({...methodSearchParams, [searchParam]:newSearchParamOptions});        }}
        />
        {option.subtype}
  </li>);
}
export default MethodOption;