import {
    restorationTypeIndex,
  } from '../../constants/methodologies';

export const sortRestorationMethods = (a, b) => {
    // Sort based on restorationTypeIndex
    const typeAIndex = restorationTypeIndex[a.type];
    const typeBIndex = restorationTypeIndex[b.type];
    if (typeAIndex < typeBIndex) {
      return -1;
    }
    if (typeAIndex > typeBIndex) {
      return 1;
    }
  
    // names must be equal
    return 0;
  };
  
export const sortScientificMethods = (a, b) => {
      // Sort alphabetically
      const typeA = a.type.toUpperCase(); // ignore upper and lowercase
      const typeB = b.type.toUpperCase(); // ignore upper and lowercase
      if (typeA < typeB) {
        return -1;
      }
      if (typeA > typeB) {
        return 1;
      }
    
      // names must be equal
      return 0;
    };

    export const isOptionEqualToValue = (option, value) => {
        return (option.subtype == value.subtype && option.type == value.type);
      };