import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import { FLAG_DETAILS as METHOD_FLAG_DETAILS } from '../../constants/methodPracticeFlags';
import { FLAG_DETAILS as ORG_FLAG_DETAILS } from '../../constants/orgFlags';

export const getMethodPracticeForUI = (methodPractice) => {
  const methodPracticeUI = {
    workSiteName: methodPractice.worksiteId.name,
    organizationName: methodPractice.organizationId.name,
    organizationPhone: methodPractice.organizationId.phone,
    organizationEmail: methodPractice.organizationId.email,
    organizationFacebook: methodPractice.organizationId.facebook,
    organizationInstagram: methodPractice.organizationId.instagram,
    organizationTwitter: methodPractice.organizationId.twitter,
    organizationLinkedIn: methodPractice.organizationId.linkedin,
    //organizationVolunteerWebsite: methodPractice.organizationId.volunteer_website,
    //organizationDonationsWebsite: methodPractice.organizationId.donations_website,
    website: methodPractice.website,
    orgWebsite: methodPractice.organizationId.website,
    restorationMethodologyData: has(methodPractice, ['restoration_methodologyId'])
      ? methodPractice.restoration_methodologyId
      : {},
    scientificMethodologyData: has(methodPractice, ['scientific_methodologyId'])
      ? methodPractice.scientific_methodologyId
      : {},
    restorationMethodologyGroupData: has(methodPractice, ['restoration_methodologyGroupId'])
      ? methodPractice.restoration_methodologyGroupId
      : {},
    scientificMethodologyGroupData: has(methodPractice, ['scientific_methodologyGroupId'])
      ? methodPractice.scientific_methodologyGroupId
      : {},
    methodsToolTipIconsInfo: getMethodToolTipIconInfoFromMethodPractice(methodPractice),
    orgsToolTipIconsInfo: getOrgToolTipIconInfoFromMethodPractice(methodPractice),
    logoPath: methodPractice.organizationId.logo_card ? methodPractice.organizationId.logo_card : methodPractice.organizationId.logo,
    lastYearActive: isEmpty(methodPractice['last_year_active'])
      ? methodPractice['last_year_active']
      : null,
    coralSpecies: getCoralSpecies(methodPractice)
  };

  return methodPracticeUI;
};

const getCoralSpecies = (methodPractice) => {
  let coralSpecies = [];
  if (isArray(methodPractice.coralSpecies)) {
    coralSpecies = methodPractice.coralSpecies.map(speciesInfo => speciesInfo.scientificName);
  }
  return coralSpecies;
}

const getMethodToolTipIconInfoFromMethodPractice = (methodPractice) => {
  const toolTipIconsInfo = [];
  for (const [columnName, details] of Object.entries(METHOD_FLAG_DETAILS)) {
    if (methodPractice[columnName]) {
      toolTipIconsInfo.push({
        label: details.label,
        IconType: details.IconType,
        iconStyle: details.iconStyle,
      });
    }
  }
  return toolTipIconsInfo;
};

const getOrgToolTipIconInfoFromMethodPractice = (methodPractice) => {
  const toolTipIconsInfo = [];
  for (const [columnName, details] of Object.entries(ORG_FLAG_DETAILS)) {
    if (methodPractice.organizationId[columnName]) {
      toolTipIconsInfo.push({
        label: details.label,
        IconType: details.IconType,
        iconStyle: details.iconStyle,
        linkUrl: details.linkOrgColumn ? methodPractice.organizationId[details.linkOrgColumn] : '',
      });
    }
  }
  return toolTipIconsInfo;
};
